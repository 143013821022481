<template>
  <v-container fluid class="white" style="min-height:50vh">
    <PageNotFound
      message="Informasi admin tidak ditemukan"
      v-if="notFound"
    />
    <main v-else>
      <section v-if="isLoading">
        <v-row v-for="skeleton in repeatSkeleton" :key="skeleton">
          <v-col cols="12" md="3">
            <v-skeleton-loader
              type="list-item"
            ></v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="5">
            <v-skeleton-loader
              type="image"
              height="35"
            ></v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="4">
            <v-skeleton-loader
              type="button"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
      </section>
      <section v-else class="mt-4">
        <v-row v-for="(item, index) in listAdminInformation" :key="index">
          <v-col cols="12" sm="2" class="d-flex">
            <h4>Admin <span class="red--text">*</span></h4>
          </v-col>
          <v-col cols="12" sm="10">
            <v-text-field
              :disabled="!(path === 'edit' && userAccess.canUpdate)"
              v-model="item.email"
              :rules="rulesEmail"
              dense
              outlined
              placeholder="Email">
            </v-text-field>
          </v-col>
          <v-col
            v-if="path === 'edit'"
            cols="12"
          >
            <v-row justify="center" align="center">
              <v-col
                cols="auto"
                v-if="item.activated === true
                  && userAccess.canUpdate"
              >
                <v-btn
                  class="mt-1"
                  color="primary"
                  small
                  @click.stop.prevent="() => resetPassword(item)"
                >
                  {{$_strings.userAdmin.RESETPASSWORD_BTN_LABEL}}
                </v-btn>
              </v-col>
              <v-col
                cols="auto"
                v-if="item.activated === false"
              >
                <v-btn
                  class="mt-1"
                  color="warning"
                  small
                  min-width="130"
                  @click.stop.prevent="() => resendEmail(item)"
                >
                  Resend Email
                </v-btn>
              </v-col>
              <v-col
                cols="auto"
                v-if="userAccess.canUpdate"
              >
                <v-btn
                  :disabled="isDisableButtonUpdateEmail(index) || !isEmailValid(item.email)"
                  class="mt-1"
                  small
                  min-width="130"
                  @click.stop.prevent="() => updateEmail(item, index)"
                >
                  Update Email
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </section>
    </main>
  </v-container>
</template>

<script>
import PageNotFound from './PageNotFound.vue';

let interval;
export default {
  name: 'admin-information',
  components: {
    PageNotFound,
  },
  data() {
    return {
      isLoading: false,
      repeatSkeleton: 2,
      listAdminInformation: [],
      oldListAdminInformation: [],
      notFound: false,
      rulesEmail: [
        (value) => {
          if (!value) {
            return this.$_strings.messages.validation.REQUIRED('Email');
          }
          return this.isEmailValid(value) || this.$_strings.messages.validation.EMAIL;
        },
      ],
    };
  },
  mounted() {
    this.fetchAdminInformation();
  },
  computed: {
    path() {
      const [,,, path] = this.$route.path.split('/');
      return path;
    },
    myUserInfo() {
      return this.$store.state.user.myUserInfo || {
        roleName: '',
      };
    },
  },
  methods: {
    setRepeatSkeleton() {
      interval = setInterval(() => {
        this.repeatSkeleton = Math.ceil(Math.random() * 4);
      }, 2000);
    },
    isDisableButtonUpdateEmail(index) {
      return this.oldListAdminInformation[index].email === this.listAdminInformation[index].email;
    },
    async fetchAdminInformation() {
      const { companyId } = this.$route.params;
      try {
        this.setRepeatSkeleton();
        this.isLoading = true;
        const [,, path] = this.$route.path.split('/');
        let service;
        if (path === 'customer') service = this.$_services.masterShipper.fetchAdminDetail;
        if (path === 'rekanan') service = this.$_services.masterPatner.fetchAdminDetail;
        const result = await service(companyId);
        if (!result.data.length) {
          this.notFound = true;
          return;
        }
        this.listAdminInformation = JSON.parse(JSON.stringify(result.data));
        this.oldListAdminInformation = JSON.parse(JSON.stringify(result.data));
      } finally {
        clearInterval(interval);
        this.isLoading = false;
      }
    },
    async resetPassword(item) {
      this.$root.$loading.show();
      try {
        await this.$_services.userAdmins.resetPassword(item);
        this.$dialog.notify.success(this.$_strings.userAdmin.SUCCESS_RESET_PASSWORD_USER_MESSAGE_TEXT);
      } finally {
        this.$root.$loading.hide();
      }
    },
    async resendEmail(item) {
      this.$root.$loading.show();
      try {
        await this.$_services.userAdmins.resendEmail(item);
        this.$dialog.notify.success(this.$_strings.userAdmin.SUCCESS_RESEND_USER_MESSAGE_TEXT);
      } finally {
        this.$root.$loading.hide();
      }
    },
    async updateEmail(item, index) {
      const [,, path] = this.$route.path.split('/');
      let service;
      if (path === 'customer') service = this.$_services.masterShipper.updateEmailMasterShipper;
      if (path === 'rekanan') service = this.$_services.masterPatner.updateEmailMasterPartner;
      const { companyUserId: userId, companyId, email } = item;
      this.$root.$loading.show();
      try {
        await service(userId, email);
        this.oldListAdminInformation.splice(index, 1, {
          ...item,
        });
        this.$dialog.notify.success('Berhasil Update Email');
      } finally {
        this.$root.$loading.hide();
      }
    },
  },
};
</script>
