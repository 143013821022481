var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',{staticClass:"mb-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{key:_vm.reFetchData,staticClass:"elevation-1",attrs:{"item-key":"id","calculate-widths":"","single-expand":"","show-expand":"","loading":_vm.isLoading,"server-items-length":_vm.totalFclBackhaulingRouteData,"options":_vm.pagination,"headers":_vm.displayedHeaderBackhauling,"items":_vm.mouFclBackhaulingRoute,"expanded":_vm.expanded,"footer-props":{
          showCurrentPage: true,
          showFirstLastPage: true,
          'items-per-page-options': _vm.$_item_per_page,
          disablePagination: _vm.isLoading,
          disableItemsPerPage: _vm.isLoading
        }},on:{"update:options":function($event){_vm.pagination=$event},"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"header.isAutoAssign",fn:function(ref){
        var header = ref.header;
return [_c('p',{staticClass:"ma-0"},[_vm._v(" "+_vm._s(header.text)+" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mb-4",attrs:{"x-small":"","color":"white"}},'v-icon',attrs,false),on),[_vm._v(" mdi-information ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.mou.A_A))])])],1)]}},{key:"item.sequenceOrderNo",fn:function(ref){
        var item = ref.item;
        var index = ref.index;
return [_c('v-text-field',{staticClass:"body-2 pt-2",attrs:{"dense":"","outlined":"","disabled":"","value":item.sequenceOrderNo ? _vm.getSequence(item.sequenceOrderNo, index) : ''}})]}},{key:"item.origin",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"blue--text"},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.originLocationName)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.order.ORIGIN))])])]}},{key:"item.destination",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"blue--text"},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.destinationLocationName)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.order.DESTINATION))])])]}},{key:"item.price",fn:function(ref){
        var item = ref.item;
return [_c('p',[_vm._v(_vm._s(_vm.formatAsCurrency(item.price)))])]}},{key:"item.isAutoAssign",fn:function(ref){
        var item = ref.item;
return [_c('p',[_vm._v(_vm._s(item.isAutoAssign ? 'Ya' : 'Tidak'))])]}},{key:"item.transportTypeId",fn:function(ref){
        var item = ref.item;
return [_c('p',[_vm._v(_vm._s(item.transportTypeName))])]}},{key:"item.priceMultiPick",fn:function(ref){
        var item = ref.item;
return [_c('p',[_vm._v(_vm._s(_vm.formatAsCurrency(item.priceMultiPick)))])]}},{key:"item.priceMultiDrop",fn:function(ref){
        var item = ref.item;
return [_c('p',[_vm._v(_vm._s(_vm.formatAsCurrency(item.priceMultiDrop)))])]}},{key:"item.data-table-expand",fn:function(ref){
        var item = ref.item;
        var isExpanded = ref.isExpanded;
        var expand = ref.expand;
return [(_vm.showExpandPickDrop(item))?_c('v-icon',{class:['v-data-table__expand-icon', { 'v-data-table__expand-icon--active': isExpanded }],on:{"click":function($event){$event.stopPropagation();return expand(!isExpanded)}}},[_vm._v(" $expand ")]):_vm._e()]}},{key:"expanded-item",fn:function(ref){
        var item = ref.item;
return [_c('td',{attrs:{"colspan":8}},[_c('v-data-table',{staticClass:"mt-2 mb-4 elevation-1 sub_header",attrs:{"headers":_vm.subHeaderTable,"items":item.mouRoutesMultiLoc,"items-per-page":100,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.origin",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"blue--text"},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.locationName)+" ")])]}}],null,true)},[_c('span',[_vm._v("Lokasi "+_vm._s(item.pickDrop === 'PICKUP' ? 'Multipick' : 'Multidrop')+" ")])])]}},{key:"item.pickDrop",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.pickDrop === 'PICKUP' ? 'Multipick' : 'Multidrop')+" ")])]}}],null,true)})],1)]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }