<template>
  <v-card
    min-height="300"
    elevation="0"
    class="pt-12"
  >
    <section v-if="isLoadingCompanyInformation">
      <v-row v-for="skeleton in 1" :key="skeleton">
        <v-col cols="12" md="3">
          <v-skeleton-loader
            type="list-item"
          ></v-skeleton-loader>
        </v-col>
        <v-col cols="12" md="5">
          <v-skeleton-loader
            type="image"
            height="35"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </section>
    <v-row v-else>
      <v-col cols="12" sm="2" class="d-flex align-center justify-center">
        <h4>Status</h4>
      </v-col>
      <v-col cols="auto" class="d-none d-sm-flex align-center">:</v-col>
      <v-col cols="12" sm="6" md="3" class="auto px-4 px-sm-0">
        <v-select
          v-model="status"
          dense
          outlined
          hide-details
          :items="statusItems"
          :disabled="isPageDetail || !showButton"
        >
        </v-select>
      </v-col>
      <v-col v-if="showButton" class="d-flex justify-center justify-sm-start">
        <v-btn
          class="mt-1"
          color="primary"
          :disabled="disableButton"
          @click="updateStatus"
        >
          Update
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: {
    isLoadingCompanyInformation: {
      type: Boolean,
      default: () => false,
    },
    statusCompany: {
      type: String,
      default: 'INACTIVE',
    },
  },
  data() {
    return {
      status: this.statusCompany,
      statusItems: ['INACTIVE', 'ACTIVE'],
      roleCanSetInactive: ['MARKETING_MANAGER'],
      roleCanSetActive: ['HOOW', 'HOCL'],
    };
  },
  watch: {
    statusCompany(newVal) {
      this.status = newVal;
    },
  },
  computed: {
    companyType() {
      const [,, path] = this.$route.path.split('/');
      if (path === 'customer') return 'shipper';
      return 'transporter';
    },
    myUserInfo() {
      return this.$store.state.user.myUserInfo;
    },
    role() {
      // MARKETING_MANAGER, HOOW, HOOCL
      return (this.myUserInfo && this.myUserInfo.roleName) || '';
    },
    isPageDetail() {
      const [,,, path] = this.$route.path.split('/');
      return path === 'detail';
    },
    showButton() {
      const accessRole = [...this.roleCanSetActive, ...this.roleCanSetInactive];
      if (!accessRole.includes(this.role)) return false;
      if (this.statusCompany === 'INACTIVE') {
        return this.roleCanSetActive.includes(this.role) && !this.isPageDetail;
      }
      return this.roleCanSetInactive.includes(this.role) && !this.isPageDetail;
    },
    disableButton() {
      if (this.status === 'INACTIVE') {
        return !this.roleCanSetInactive.includes(this.role);
      }
      return !this.roleCanSetActive.includes(this.role);
    },
  },
  methods: {
    async updateStatus() {
      const { companyId } = this.$route.params;
      let service = this.$_services.masterShipper.updateStatusCompany;
      if (this.companyType === 'transporter') service = this.$_services.masterPatner.updateStatusCompany;
      try {
        this.$root.$loading.show();
        await service(companyId, this.status);
        this.$dialog.notify.success('Berhasil memperbarui status perusahaan');
        this.$emit('updateFormStatusCompany', this.status);
      } finally {
        this.$root.$loading.hide();
      }
    },
  },
};
</script>
