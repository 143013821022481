<template>
  <v-container class="mb-4 white" fluid>
    <PageNotFoundMou
      v-if="!$route.params.mouId"
      message="Mou tidak ditemukan"
    />
    <main v-else>
      <common-error
        v-if="isErrorGetMou"
        @fetchData="$emit('fetchMou')"
      />
      <section v-else-if="isLoadingFetchMou">
        <v-row v-for="skeleton in 10" :key="skeleton">
          <v-col cols="12" md="3">
            <v-skeleton-loader
              type="list-item"
            ></v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="9">
            <v-skeleton-loader
              type="image"
              height="35"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
      </section>
      <v-form
        v-else
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-row class="mt-1">
          <v-col class="pt-0" cols="12" sm="3">
            <label class="body-2 ma-0" for="selectCompany">{{$_strings.mou.COMPANY_NAME_TABLE_HEADER_LABEL}}
              <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
            </label>
          </v-col>
          <v-col class="pt-0">
            <v-autocomplete
              id="selectCompany"
              class="body-2"
              item-value="id"
              v-model="form.companyId"
              item-text="name"
              :items="itemsCompany"
              placeholder="Nama Perusahaan"
              outlined
              :disabled="readonly || !showBottomButton"
              :loading="isLoadingGetCompany"
              :clearable="!readonly"
              dense
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="pt-0" cols="12" sm="3">
            <label class="body-2 ma-0" for="textInitial">{{$_strings.mou.ALIAS_INITIAL_TABLE_HEADER_LABEL}}
              <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
            </label>
          </v-col>
          <v-col class="pt-0">
            <v-text-field
              id="textInitial"
              disabled
              class="body-2"
              v-model="form.companyAbbreviation"
              required
              dense
              outlined>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="pt-0" cols="12" sm="3">
            <label class="body-2 ma-0" for="noContract">{{$_strings.mou.NO_CONTRACT}}
            </label>
          </v-col>
          <v-col class="pt-0">
            <v-text-field
              id="noContract"
              class="body-2"
              v-model="form.contractNo"
              placeholder="Autogenerate"
              required
              disabled
              dense
              outlined
              filled
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="pt-0" cols="12" sm="3">
            <label class="body-2 ma-0" for="mouNumber">{{$_strings.mou.MOU_NUMBER_TABLE_HEADER_LABEL}}
            </label>
          </v-col>
          <v-col class="pt-0">
            <v-text-field
              id="mouNumber"
              class="body-2"
              v-model="form.mouNo"
              placeholder="Autogenerate"
              required
              disabled
              dense
              outlined
              filled
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="pt-0" cols="12" sm="3">
            <label class="body-2 ma-0" for="periodicCooperation">{{$_strings.mou.PERIODIC_COOPERATION}}
              <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
            </label>
          </v-col>
          <v-col cols="auto" sm="3" class="pt-0">
            <v-menu
              :close-on-content-click="true"
              :return-value.sync="form.mouPeriodStart"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  id="periodicCooperation"
                  dense
                  outlined
                  :value="dateFormat(form.mouPeriodStart)"
                  class="body-2"
                  placeholder="From Date"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  :disabled="readonly"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="form.mouPeriodStart"
                no-title
                :min="new Date().toISOString().substr(0, 10)"
                :max="form.mouPeriodEnd"
                scrollable
                :disabled="readonly"
                locale="id"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="auto" class="pt-0">
            <span class="body-2 ma-0">{{$_strings.mou.TO}} <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
            </span>
          </v-col>
          <v-col cols="auto" sm="3" class="pt-0">
            <v-menu
              :close-on-content-click="true"
              :return-value.sync="form.mouPeriodEnd"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  id="periodicCooperation"
                  dense
                  outlined
                  :value="dateFormat(form.mouPeriodEnd)"
                  class="body-2"
                  placeholder="To Date"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  :disabled="readonly"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                :disabled="readonly"
                v-model="form.mouPeriodEnd"
                no-title
                :min="form.mouPeriodStart"
                scrollable
                locale="id"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="pt-0" cols="12" sm="3">
            <label class="body-2 ma-0" for="periodicCooperation">{{$_strings.mou.PERIODE_START_DOC}}
              <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
            </label>
          </v-col>
          <v-col cols="auto" sm="3" class="pt-0">
            <v-menu
              :close-on-content-click="true"
              :return-value.sync="form.mouPeriodStartDoc"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  id="periodicCooperation"
                  dense
                  outlined
                  :value="dateFormat(form.mouPeriodStartDoc)"
                  :disabled="readonly"
                  class="body-2"
                  placeholder="Dari tanggal"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                :disabled="readonly"
                v-model="form.mouPeriodStartDoc"
                no-title
                scrollable
                locale="id"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="auto" class="pt-0">
            <span class="body-2 ma-0">{{$_strings.mou.TO}} <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
            </span>
          </v-col>
          <v-col cols="auto" sm="3" class="pt-0">
            <v-menu
              :close-on-content-click="true"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  outlined
                  :value="dateFormat(form.mouPeriodEnd)"
                  disabled
                  class="body-2"
                  placeholder="Sampai tanggal"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                disabled
                v-model="form.mouPeriodEnd"
                no-title
                scrollable
                locale="id"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row class="mt-4">
          <v-col class="pt-0" cols="12" sm="3">
            <label class="body-2 ma-0" for="autoExtendMonth">{{$_strings.mou.AUTO_EXTENDOR}}
              <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
            </label>
          </v-col>
          <v-col cols="auto" class="mt-0 pt-0">
            <v-radio-group :disabled="readonly" class="mt-0 pa-0" row v-model="form.isAutoExtend">
              <v-radio
                :key="1"
                :value="true"
                label="Ya"
              ></v-radio>
              <v-radio
                :key="2"
                :value="false"
                label="Tidak"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col class="pt-0">
            <v-select
              :disabled="readonly"
              v-if="form.isAutoExtend"
              placeholder="Pilih Bulan"
              v-model="form.autoExtendMonth"
              :items="monthItems"
              item-value="value"
              item-text="name"
              class="body-2"
              outlined
              dense
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="mt-5">
          <v-col class="pt-0" cols="12" sm="3">
            <label class="body-2 ma-0" for="termOfPayment">{{$_strings.mou.TERM_OF_PAYMENT}}
              <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
            </label>
          </v-col>
          <v-col class="pt-0">
            <v-autocomplete
              :disabled="readonly"
              id="termOfPayment"
              v-model="form.termOfPayment"
              :items="form.termOfPayment ? [form.termOfPayment, ...itemsTop] : itemsTop"
              :loading="isLoadingListTop"
              item-value="value"
              item-text="name"
              class="body-2"
              outlined
              dense
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row class="mt-4">
          <v-col class="pt-0" cols="12" sm="3">
            <label class="body-2 ma-0" for="paymentMethod">{{$_strings.mou.PAYMENT_METHOD}}
            </label>
          </v-col>
          <v-col cols="auto" class="mt-0 pt-0">
            <v-radio-group :disabled="readonly" class="mt-0 pa-0" row v-model="radioButtonPaymentMethod">
              <v-radio
                :value="1"
                label="Ya"
              ></v-radio>
              <v-radio
                :value="2"
                label="Tidak"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col class="pt-0">
            <v-text-field
              v-if="radioButtonPaymentMethod === 1"
              id="paymentMethod"
              class="body-2"
              v-model="form.paymentMethod"
              dense
              disabled
              outlined>
            </v-text-field>
            <v-text-field
              v-else
              id="paymentMethod"
              class="body-2"
              v-model="form.paymentMethod"
              dense
              :disabled="readonly"
              outlined>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-4">
          <v-col class="pt-0" cols="12" sm="3">
            <label class="body-2 ma-0" for="paymentPic">{{$_strings.mou.PAYMENT_PIC}}
              <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
            </label>
          </v-col>
          <v-col cols="auto" class="mt-0 pt-0">
            <v-select
              :disabled="readonly"
              v-model="form.paymentPicSalutation"
              :items="picTitleItems"
              outlined
              class="body-2"
              dense
            ></v-select>
          </v-col>
          <v-col class="pt-0">
            <v-text-field
              :disabled="readonly"
              id="paymentPic"
              v-model="form.paymentPic"
              class="body-2"
              dense
              outlined>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="pt-0" cols="12" sm="3">
            <label class="body-2 ma-0" for="paymentPicMsisdn">{{$_strings.mou.PAYMENT_PIC_NUMBER_PHONE}}
              <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
            </label>
          </v-col>
          <v-col class="pt-0">
            <phone-number-format
              :disabled="readonly"
              id="paymentPicMsisdn"
              v-model="form.paymentPicMsisdn"
              dense
              class="body-2"
              outlined
              :required="readonly ? false : true"
            >
            </phone-number-format>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="pt-0" cols="12" sm="3">
            <label class="body-2 ma-0" for="paymentOperational">{{$_strings.mou.OPERATIONAL_PIC}}
              <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
            </label>
          </v-col>
          <v-col cols="auto" class="mt-0 pt-0">
            <v-select
              :disabled="readonly"
              v-model="form.operationalPicSalutation"
              :items="picTitleItems"
              outlined
              class="body-2"
              dense
            ></v-select>
          </v-col>
          <v-col class="pt-0">
            <v-text-field
              :disabled="readonly"
              id="paymentOperational"
              v-model="form.operationalPic"
              class="body-2"
              dense
              outlined>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="pt-0" cols="12" sm="3">
            <label class="body-2 ma-0" for="operationalPicMsisdn">No Telepon PIC Operasional
              <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
            </label>
          </v-col>
          <v-col class="pt-0">
            <phone-number-format
              :disabled="readonly"
              id="operationalPicMsisdn"
              v-model="form.operationalPicMsisdn"
              dense
              class="body-2"
              outlined
              :required="readonly ? false : true"
            >
            </phone-number-format>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="pt-0" cols="12" sm="3">
            <label class="body-2 ma-0" for="operationalPicJobTitle">Posisi PIC Operasional
              <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
            </label>
          </v-col>
          <v-col class="pt-0">
            <v-text-field
              :disabled="readonly"
              id="operationalPicJobTitle"
              v-model="form.operationalPicJobTitle"
              dense
              class="body-2"
              outlined>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-4">
          <v-col class="pt-0" cols="12" sm="3">
            <label class="body-2 ma-0" for="clientInsurance">{{$_strings.mou.CLIENTS_USING_INSURANCE}}
            </label>
          </v-col>
          <v-col cols="auto" class="mt-0 pt-0">
            <v-radio-group :disabled="readonly" id="clientInsurance" class="mt-0 pa-0" row v-model="form.isInsurance">
              <v-radio
                :value="true"
                label="Ya"
              ></v-radio>
              <v-radio
                :value="false"
                label="Tidak"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row class="mt-4">
          <v-col class="pt-0" cols="12" sm="3">
            <label class="body-2 ma-0" for="clientInsurance">{{$_strings.mou.FINANCIAL_STATEMENTS_KAP}}
            </label>
          </v-col>
          <v-col cols="auto" class="mt-0 pt-0">
            <v-radio-group :disabled="readonly" id="clientInsurance" class="mt-0 pa-0" row v-model="form.isAuditedByKap">
              <v-radio
                :value="true"
                label="Ya"
              ></v-radio>
              <v-radio
                :value="false"
                label="Tidak"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row v-if="mouBy === 'customer'" class="mt-0">
          <v-col class="pt-0" cols="12" sm="3">
            <label class="body-2 ma-0" for="averageShipment">Rata-rata Nilai Barang Per Pengiriman
              <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
            </label>
          </v-col>
          <v-col class="pt-0">
            <v-text-field
              :disabled="readonly"
              prefix="Rp"
              id="averageShipment"
              :value="formatAsCurrency(form.averageShipment)"
              dense
              outlined>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="pt-0" cols="12" sm="3">
            <label class="body-2 ma-0" for="totalAsset">{{$_strings.mou.TOTAL_ASSET}}</label>
          </v-col>
          <v-col class="pt-0">
            <v-text-field
              :disabled="readonly"
              id="totalAsset"
              prefix="Rp"
              :value="formatAsCurrency(form.totalAsset)"
              dense
              outlined>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="pt-0" cols="12" sm="3">
            <label class="body-2 ma-0" for="ventureCapital">{{$_strings.mou.STARTUP_CAPITAL}}</label>
          </v-col>
          <v-col class="pt-0">
            <v-text-field
              :disabled="readonly"
              prefix="Rp"
              id="ventureCapital"
              :value="formatAsCurrency(form.ventureCapital)"
              dense
              outlined>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="pt-0" cols="12" sm="3">
            <label class="body-2 ma-0" for="shareHolder">{{$_strings.mou.SHAREHOLDERS}}
            </label>
          </v-col>
          <v-col class="pt-0">
            <v-text-field
              :disabled="readonly"
              id="shareHolder"
              v-model="form.shareholder"
              dense
              outlined>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="pt-0" cols="12" sm="3">
            <label class="body-2 ma-0" for="bankName">{{$_strings.mou.BANK_NAME}}
              <v-icon v-if="radioButtonPaymentMethod === 1" color="red" class="icon_important">mdi-asterisk</v-icon>
            </label>
          </v-col>
          <v-col class="pt-0">
            <v-text-field
              :disabled="readonly"
              id="bankName"
              v-model="form.bankName"
              dense
              outlined>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="pt-0" cols="12" sm="3">
            <label class="body-2 ma-0" for="bankBranch">{{$_strings.mou.DISTRIBUTARY}}
              <v-icon  v-if="radioButtonPaymentMethod === 1" color="red" class="icon_important">mdi-asterisk</v-icon>
            </label>
          </v-col>
          <v-col class="pt-0">
            <v-text-field
              :disabled="readonly"
              id="bankBranch"
              v-model="form.bankBranch"
              dense
              outlined>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="pt-0" cols="12" sm="3">
            <label class="body-2 ma-0" for="accountOwner">{{$_strings.mou.ACCOUNT_OWNER}}
              <v-icon v-if="radioButtonPaymentMethod === 1" color="red" class="icon_important">mdi-asterisk</v-icon>
            </label>
          </v-col>
          <v-col cols="auto" class="mt-0 pt-0">
            <v-select
              :disabled="readonly"
              v-model="form.bankAccountNameSalutation"
              :items="accountOwnerItems"
              outlined
              class="body-2"
              dense
            ></v-select>
          </v-col>
          <v-col class="pt-0">
            <v-text-field
              :disabled="readonly"
              id="bankAccountName"
              v-model="form.bankAccountName"
              class="body-2"
              dense
              outlined>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="pt-0" cols="12" sm="3">
            <label class="body-2 ma-0" for="accountNumber">{{$_strings.mou.ACCOUNT_NUMBER}}
              <v-icon v-if="radioButtonPaymentMethod === 1" color="red" class="icon_important">mdi-asterisk</v-icon>
            </label>
          </v-col>
          <v-col class="pt-0">
            <v-text-field
              :disabled="readonly"
              id="accountNumber"
              v-mask="{'mask': '9', 'repeat': 30, allowMinus: false, rightAlign: false}"
              v-model="form.bankAccountNo"
              dense
              outlined>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="pt-0" cols="12" sm="3">
            <label class="body-2 ma-0" for="typeOfService">Service Types
            </label>
          </v-col>
          <v-col class="pt-0">
            <v-checkbox
              :disabled="readonly"
              class="ma-0"
              label="Intergrated Service"
              color="primary"
              v-model="form.isIntegratedService"
              hide-details
            ></v-checkbox>
            <v-checkbox
              :disabled="readonly"
              label="Transportation"
              color="primary"
              v-model="form.isTransportation"
              hide-details
            ></v-checkbox>
            <div class="pl-8" v-if="form.isTransportation">
              <v-checkbox
                :disabled="readonly"
                v-model="form.isMultiPick"
                label="Multipick"
                color="primary"
                hide-details
              ></v-checkbox>
              <v-checkbox
                :disabled="readonly"
                v-model="form.isMultiDrop"
                label="Multidrop"
                color="primary"
                hide-details
              ></v-checkbox>
              <v-checkbox
                :disabled="readonly"
                v-model="form.isBackhauling"
                label="Backhauling"
                color="primary"
                hide-details
              ></v-checkbox>
              <v-checkbox
                :disabled="readonly"
                v-model="form.isMultiModa"
                label="Multimoda"
                color="primary"
                hide-details
              ></v-checkbox>
              <v-checkbox
                :disabled="readonly"
                v-model="form.isMultiShipper"
                label="Multishipper"
                color="primary"
                hide-details
              ></v-checkbox>
            </div>
            <v-checkbox
              :disabled="readonly"
              label="Warehousing"
              color="primary"
              v-model="form.isWarehousing"
              hide-details
            ></v-checkbox>
          </v-col>
        </v-row>
        <!-- P2P_LANDING -->
        <v-row v-if="mouBy === 'rekanan' || form.p2pLending.length > 0">
          <v-col class="pt-1" cols="12" sm="3">
            <label class="body-2" for="isP2pLending">{{$_strings.mou.P2P_LENDING}}
            </label>
          </v-col>
          <v-col cols="auto" class="pt-0">
            <v-checkbox
              :disabled="readonly"
              class="mt-0"
              v-model="form.isP2pLending" />
          </v-col>
          <v-col v-if="form.isP2pLending" cols="12" sm="8" class="pl-0">
            <v-data-table
              :headers="displayedHeaderP2PLanding"
              :items="form.p2pLending"
              item-key="id"
              hide-default-footer
            >
              <template v-slot:[`item.top`]=items>
                <span v-if="items.item.top">{{items.item.top}} Hari</span>
              </template>
              <template v-slot:[`item.adminRate`]=items>
                <span v-if="items.item.top">{{replaceDotToComma(items.item.adminRate)}} %</span>
              </template>
              <template v-slot:[`item.interestRate`]=items>
                <span v-if="items.item.top">{{replaceDotToComma(items.item.interestRate)}} %</span>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-form>
    </main>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import PageNotFoundMou from './PageNotFound.vue';
import { dateFormat } from '../../../helper/commonHelpers';

export default {
  name: 'cooperation-information',
  components: {
    PageNotFoundMou,
  },
  props: {
    form: {
      type: Object,
      default: () => {},
    },
    fetchMouDetail: {
      type: Function,
    },
    readonly: {
      type: Boolean,
      default: () => false,
    },
  },
  created() {
    const { mouId } = this.$route.params;
    const { id } = this.form;
    if (mouId && !id) {
      this.fetchMou(mouId);
    }
  },
  mounted() {
    this.setMonth();
  },
  data() {
    const { companyId, companyName } = this.form;
    return {
      isLoadingFetchMou: false,
      showBottomButton: true,
      isLoadingGetCompany: false,
      valid: false,
      itemsCompany: companyId && companyName ? [{
        id: this.form.companyId,
        name: this.form.companyName,
      }] : [],
      picTitleItems: ['Bpk', 'Ibu'],
      accountOwnerItems: ['Bpk', 'Ibu', 'PT', 'CV'],
      monthItems: [],
      itemsTop: [],
      itemsTopLending: [],
      headersP2PLanding: [
        {
          text: this.$_strings.mou.TOP,
          value: 'top',
          sortable: false,
        },
        {
          text: this.$_strings.mou.ADMIN_RATE,
          value: 'adminRate',
          sortable: false,
        },
        {
          text: this.$_strings.mou.INTEREST_RATE,
          value: 'interestRate',
          sortable: false,
        },
      ],
      isErrorGetMou: false,
      isLoadingListTop: false,
      isLoadingListTopLending: false,
    };
  },
  methods: {
    dayjs,
    formatAsCurrency(value) {
      const formatNumber = Intl.NumberFormat('id-ID');
      return formatNumber.format(value) || 0;
    },
    async fetchMou(mouId) {
      try {
        this.isLoadingFetchMou = true;
        this.isErrorGetMou = false;
        await this.fetchMouDetail(mouId);
        this.itemsCompany.push({
          id: this.form.companyId,
          name: this.form.companyName,
        });
      } catch {
        this.isErrorGetMou = true;
      } finally {
        this.isLoadingFetchMou = false;
      }
    },
    setMonth() {
      for (let i = 1; i <= 12; i += 1) {
        const month = { };
        month.name = `Bulan ${i}`;
        month.value = i;
        this.monthItems.push(month);
      }
    },
    replaceDotToComma(number) {
      const numberToString = number.toString();
      return numberToString && numberToString.toString().indexOf('.') > -1 ? numberToString.toString().replace('.', ',') : numberToString;
    },
    dateFormat,
  },
  computed: {
    mouBy() {
      const [,, mouBy] = this.$route.path.split('/');
      return mouBy;
    },
    displayedHeaderP2PLanding() {
      return this.headersP2PLanding.map((i) => ({
        ...i,
        class: 'white--text primary text-capitalize',
        cellClass: 'clickable',
      }));
    },
    radioButtonPaymentMethod: {
      get() {
        const { paymentMethod } = this.form;
        if (paymentMethod === 'TRANSFER') return 1;
        return 2;
      },
      set() {},
    },
  },
};
</script>
