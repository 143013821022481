<template>
  <v-container fluid class="white" style="min-height:50vh;">
    <section v-if="isLoadingCompanyInformation">
      <v-row v-for="skeleton in repeatSkeleton" :key="skeleton">
        <v-col cols="12" md="3">
          <v-skeleton-loader
            type="list-item"
          ></v-skeleton-loader>
        </v-col>
        <v-col cols="12" md="5">
          <v-skeleton-loader
            type="image"
            height="35"
          ></v-skeleton-loader>
        </v-col>
        <v-col cols="12" md="4">
          <v-skeleton-loader
            type="button"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </section>
    <section v-else class="mt-4">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row class="pt-2">
          <v-col cols="12" sm="3" class="pt-5">
            <h4 class="ml-12">Integrasi Oracle : </h4>
          </v-col>
          <v-col cols="12" sm="6" md="7">
            <v-select
              v-model="formData.isOracleIntegrated"
              dense
              outlined
              hide-details
              :items="statusList"
              item-value="key"
              item-text="value"
              :disabled="isDisableForm"
              @change="onChangeIsOracleIntegrated"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row class="pt-5">
          <v-col cols="12" sm="3" class="pt-5">
            <h4 class="ml-12">Grup Shipper : </h4>
          </v-col>
          <v-col cols="12" sm="6" md="7" class="pt-1 pb-0">
            <v-select
              v-model="formData.isGroup"
              dense
              outlined
              hide-details
              :items="statusGroup"
              item-value="key"
              item-text="value"
              :disabled="isDisableForm || isFormOracleInactive"
              @change="onChangeIsGroup"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row class="pt-5">
          <v-col cols="12" sm="3">
            <h4 class="ml-12 pt-4">Kode Oracle : </h4>
          </v-col>
          <v-col cols="12" sm="6" md="7">
            <v-text-field
              :readonly="!formData.isGroup"
              v-model="formData.oracleId"
              dense
              outlined
              :rules="formData.isGroup && !isFormOracleInactive ? [v => !!v || $_strings.messages.validation.REQUIRED('Kode Oracle')]: []"
              v-mask="{'mask': '9', 'repeat': 30, allowMinus: false, rightAlign: false}"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="3" class="pt-5">
            <h4 class="ml-12">Kode Area : </h4>
          </v-col>
          <v-col cols="12" sm="6" md="7" class="pt-1 pb-0">
            <v-autocomplete
              v-model="formData.areaCodeId"
              dense
              outlined
              :loading="isLoadingCodeArea"
              :items="areaCodeList"
              item-value="id"
              item-text="name"
              :rules="isFormOracleInactive ? [] : rules.areaCodeRule"
              :disabled="isDisableForm || isFormOracleInactive"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row class="pt-5">
          <v-col cols="12" sm="3" class="py-5">
            <h4 class="ml-12">Url Lokasi : </h4>
          </v-col>
          <v-col cols="12" sm="6" md="7" class="pt-1 pb-0">
            <v-text-field
              :disabled="isDisableForm || isFormOracleInactive"
              v-model="formData.urlApi"
              dense
              outlined
            />
          </v-col>
        </v-row>
        <v-row class="pt-5">
          <v-col cols="12" sm="3" class="pt-5">
            <h4 class="ml-12">Kategori Barang:</h4>
          </v-col>
          <v-col cols="12" sm="6" md="7" class="pt-1 pb-0">
            <common-auto-complete-items
              dense
              type="categoryList"
              searchName="name"
              item-value="id"
              item-text="name"
              class="body-2"
              prepend-inner-icon="mdi-package-variant-closed"
              outlined
              v-model="formData.categoryId"
              :disabled="isDisableForm || isFormOracleInactive"
              placeholder="Kategori"
              :rules="formData.isGroup && !isFormOracleInactive ? rules.category : []"
              :filter="categoryFilter"
              :items="categoryItems"
              @updateItems="updateCategoryItems"
              @change="resetSubCategoryItems"
            />
          </v-col>
        </v-row>
        <v-row class="pt-5">
          <v-col cols="12" sm="3" class="pt-5">
            <h4 class="ml-12">Sub Kategori Barang:</h4>
          </v-col>
          <v-col cols="12" sm="6" md="7" class="pt-1 pb-0">
            <common-auto-complete-items
              dense
              :type="formData.categoryId ? 'subCategoryList' : null"
              searchName="name"
              item-value="id"
              item-text="name"
              class="body-2"
              prepend-inner-icon="mdi-package-variant-closed"
              outlined
              :optional="{
                categoryId: formData.categoryId
              }"
              v-model="formData.subCategoryId"
              :disabled="isDisableForm || isFormOracleInactive"
              placeholder="Sub Kategori"
              :items="subCategoryItems"
              :rules="formData.isGroup && !isFormOracleInactive ? rules.subCategory : []"
              :filter="subCategoryFilter"
              @updateItems="updateSubCategoryItems"
            />
          </v-col>
        </v-row>
        <v-row class="pt-5">
          <v-col cols="12" sm="3" class="pt-5">
            <h4 class="ml-12 -pt-4">Kategori Shipper : </h4>
          </v-col>
          <v-col cols="12" sm="6" md="7" class="pt-1 pb-0">
            <v-autocomplete
              v-model="formData.shipperCategoryId"
              dense
              outlined
              :loading="isLoadingCategoryShipper"
              :items="categoryShipperList"
              item-value="id"
              item-text="name"
              :rules="isFormOracleInactive ? [] : rules.categoryShipperRule"
              :disabled="isDisableForm || isFormOracleInactive"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row class="pt-5">
          <v-col cols="12" sm="3" class="pt-2">
            <h4 class="ml-12">Jenis Shipper : </h4>
          </v-col>
          <v-col cols="12" sm="6" md="7" class="pt-1 pb-0">
            <v-autocomplete
              v-model="formData.shipperGroupId"
              dense
              outlined
              :loading="isLoadingTypeShipper"
              :items="typeShipperList"
              item-value="id"
              item-text="name"
              :rules="isFormOracleInactive ? [] : rules.typeShipperRule"
              :disabled="isDisableForm || isFormOracleInactive"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row class="pt-5">
          <v-col cols="12" sm="3">
            <h4 class="ml-12">Sales : </h4>
          </v-col>
          <v-col cols="12" sm="6" md="7" class="pt-1 pb-0">
            <v-autocomplete
              v-model="formData.salesId"
              dense
              outlined
              :loading="isLoadingMasterSales"
              :items="masterSalesList"
              item-value="id"
              item-text="name"
              :rules="formData.isGroup || isFormOracleInactive? [] : rules.SalesRule"
              :disabled="isDisableForm || isFormOracleInactive"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-col v-if="userAccess.canUpdate" cols="1" class="ml-md-auto ma-6">
          <v-btn
            class="mt-1"
            color="primary"
            @click="onSubmit"
            :disabled="isDisableForm"
          >
            Update
          </v-btn>
        </v-col>
        <v-row>
        </v-row>
      </v-form>
    </section>
  </v-container>
</template>

<script>
export default {
  name: 'oracle-integration-setting',
  props: {
    isLoadingCompanyInformation: {
      type: Boolean,
      default: () => false,
    },
    readonly: {
      type: Boolean,
      default: () => true,
    },
    form: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      valid: false,
      repeatSkeleton: 5,
      statusApi: '',
      statusIsGroup: '',
      statusList: this.$_strings.masterShipper.statusActivated,
      statusGroup: this.$_strings.masterShipper.statusGroup,
      isLoadingCodeArea: false,
      isLoadingCategoryShipper: false,
      isLoadingTypeShipper: false,
      isLoadingMasterSales: false,
      areaCodeList: [],
      categoryShipperList: [],
      typeShipperList: [],
      masterSalesList: [],
      defaultAreaCode: {
        id: '',
        name: '',
      },
      defaultSales: {
        id: '',
        name: '',
      },
      defaultCategoryShipper: {
        id: '',
        name: '',
      },
      defaultTypeShipper: {
        id: '',
        name: '',
      },
      rules: {
        areaCodeRule: [
          (v) => !!v || 'Kode area harus diisi',
        ],
        SalesRule: [
          (v) => !!v || 'Sales harus diisi',
        ],
        categoryShipperRule: [
          (v) => !!v || 'Kategori Shipper harus diisi',
        ],
        typeShipperRule: [
          (v) => !!v || 'Jenis Shipper harus diisi',
        ],
        category: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED('Kategori')],
        subCategory: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED('Sub Kategori')],
      },
      subCategoryItems: [],
      categoryItems: [],
      categoryFilter: {
        page: 0,
        size: 50,
        totalData: 0,
      },
      subCategoryFilter: {
        page: 0,
        size: 50,
        totalData: 0,
      },
      formData: {
        oracleId: this.form.oracleId,
        areaCodeId: this.form.areaCodeId,
        isOracleIntegrated: this.form.isOracleIntegrated,
        isGroup: this.form.isGroup,
        shipperCategoryId: this.form.companyCategoryId,
        shipperGroupId: this.form.companyGroupId,
        salesId: this.form.salesId,
        categoryId: this.form.categoryId,
        subCategoryId: this.form.subCategoryId,
        urlApi: this.form.urlApi,
      },
    };
  },
  mounted() {
    this.generateCodeAreaList();
    this.generateMasterSalesList();
    this.generateShipperCategoryList();
    this.generateShipperTypeList();
    this.setStatus();
    this.setItemsCategorySubCategory();
  },
  computed: {
    isFormOracleInactive() {
      return !this.formData.isOracleIntegrated;
    },
    isDisableForm() {
      return this.route === 'detail';
    },
    myUserInfo() {
      return this.$store.state.user.myUserInfo;
    },
    route() {
      const [,,, isEdit] = this.$route.path.split('/');
      return isEdit;
    },
  },
  methods: {
    setItemsCategorySubCategory() {
      this.categoryItems = [
        {
          id: this.form.categoryId,
          name: this.form.categoryName || '',
        },
      ];
      this.subCategoryItems = [
        {
          id: this.form.subCategoryId,
          name: this.form.subCategoryName || '',
        },
      ];
    },
    resetSubCategoryItems() {
      this.subCategoryFilter = {
        page: 0,
        size: 50,
        totalData: 0,
      };
      if (this.form.subCategoryId) this.form.subCategoryId = null;
      this.subCategoryItems = [];
    },
    updateSubCategoryItems(items) {
      this.subCategoryItems = [...this.subCategoryItems, ...items];
    },
    updateCategoryItems(items) {
      this.categoryItems = [...this.categoryItems, ...items];
    },
    setStatus() {
      this.statusApi = this.form.isOracleIntegrated;
      this.statusIsGroup = this.form.isGroup;
    },
    onChangeIsOracleIntegrated() {
      if (!this.formData.isOracleIntegrated) {
        this.formData = {
          ...this.formData,
          oracleId: this.form.oracleId,
          areaCodeId: this.form.areaCodeId,
          isGroup: this.form.isGroup,
          shipperCategoryId: this.form.companyCategoryId,
          shipperGroupId: this.form.companyGroupId,
          salesId: this.form.salesId,
          categoryId: this.form.categoryId,
          subCategoryId: this.form.subCategoryId,
          urlApi: this.form.urlApi,
        };
      }
    },
    onChangeIsGroup() {
      this.formData.oracleId = this.form.oracleId;
      if (this.formData.isGroup) {
        const findType = this.typeShipperList.find((x) => x.name.toLowerCase() === 'principal');
        if (findType) {
          this.formData.shipperGroupId = findType.id;
        }
      }
    },
    async generateCodeAreaList() {
      const filter = {
        page: 0,
        size: 150,
      };
      this.isLoadingCodeArea = true;
      try {
        const result = await this.$_services.areaCode.fetchList(filter);
        this.areaCodeList = result.data.contents;
      } finally {
        this.isLoadingCodeArea = false;
      }
    },
    async generateMasterSalesList() {
      const filter = {
        page: 0,
        size: 150,
      };
      try {
        this.isLoadingMasterSales = true;
        const result = await this.$_services.masterSales.getListMasterSales(filter);
        result.data.contents.forEach((element) => {
          this.masterSalesList.push({
            id: element.id,
            name: `${element.salesCode} - ${element.name}`,
          });
        });
      } finally {
        this.isLoadingMasterSales = false;
      }
    },
    async generateShipperCategoryList() {
      const filter = {
        page: 0,
        size: 150,
      };
      try {
        this.isLoadingCategoryShipper = true;
        const result = await this.$_services.masterShipper.getListShipperCategory(filter);
        this.categoryShipperList = result.data.contents;
      } finally {
        this.isLoadingCategoryShipper = false;
      }
    },
    async generateShipperTypeList() {
      const filter = {
        page: 0,
        size: 150,
      };
      try {
        this.isLoadingTypeShipper = true;
        const result = await this.$_services.masterShipper.getListShipperType(filter);
        this.typeShipperList = result.data.contents;
      } finally {
        this.isLoadingTypeShipper = false;
      }
    },
    async onSubmit() {
      if (!this.$refs.form.validate()) {
        return;
      }
      const companyId = this.form.id;
      this.$root.$loading.show();
      try {
        await this.$_services.masterShipper.updateOracleIntegration({ companyId, form: this.formData });
        this.$dialog.notify.success('Berhasil');
        this.$emit('fetchCompanyInformation');
      } finally {
        this.$root.$loading.hide();
      }
    },
  },
};
</script>
