import { render, staticRenderFns } from "./Backhauling.vue?vue&type=template&id=623dc25e&scoped=true&"
import script from "./Backhauling.vue?vue&type=script&lang=js&"
export * from "./Backhauling.vue?vue&type=script&lang=js&"
import style0 from "./Backhauling.vue?vue&type=style&index=0&id=623dc25e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "623dc25e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VCol,VContainer,VDataTable,VIcon,VRow,VTextField,VTooltip})
