<template>
  <v-container fluid class="white">
    <PageNotFoundMou
      v-if="!$route.params.mouId"
      message="Mou tidak ditemukan"
    />
    <main v-else>
      <common-error
        v-if="isErrorMouLocation"
        @fetchData="isErrorMouLocation = false"
      />
      <section v-else>
        <!-- Data Location -->
        <span>{{$_strings.mou.LOCATION}}</span>
        <v-row class="mt-2 mb-4">
          <v-col cols="12">
            <v-data-table
              :headers="displayedHeaderLocation"
              :items="mouLocations"
              :server-items-length="totalDataMouLocation"
              :options.sync="mouLocationPagination"
              :loading="isLoadingMouLocation"
              :footer-props="{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': $_item_per_page}"
              item-key="id"
              class="elevation-1"
            >
              <template v-slot:[`item.locationName`]=items>
                <span>{{items.item.locationName}}</span>
              </template>
              <template v-slot:[`item.address`]=items>
                <span>{{items.item.address}}</span>
              </template>
              <template v-slot:[`item.locationCityName`]=items>
                <span>{{items.item.locationCityName}}</span>
              </template>
              <template v-slot:[`item.googleMaps`]={item}>
                <span>
                  {{`Lat: ${item.latitude} Long: ${item.longitude}`}}
                </span>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </section>

      <common-error
        v-if="isErrorMouTransportTypes"
        @fetchData="isErrorMouTransportTypes = false"
      />
      <section v-else>
        <!-- Data Transporter -->
        <span>{{$_strings.mou.TRANSPORT_TYPE}}</span>
        <v-row class="mt-2">
          <v-col cols="12">
            <v-data-table
              item-key="id"
              class="elevation-1"
              :headers="displayedHeaderTransporter"
              :items="mouTransportTypes"
              :server-items-length="totalDataMouTransportTypes"
              :options.sync="mouTransportTypesPagination"
              :loading="isLoadingMouTransportTypes"
              :footer-props="{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': $_item_per_page}"
            >
              <template v-slot:[`item.name`]=items>
                <span>{{items.item.name}}</span>
              </template>
              <template v-slot:[`item.description`]={item}>
                <span>{{`${item.length} x ${item.width} x ${item.height}`}}</span>
              </template>
              <template v-slot:[`item.image`]={item}>
                <v-img
                  contain
                  alt="Image Transporter"
                  :src="item.imageUrl"
                  :height="150"
                  :width="150"
                  aspect-ratio="1"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </section>
    </main>
  </v-container>
</template>

<script>
import PageNotFoundMou from './PageNotFound.vue';

export default {
  name: 'data-location-transporter-page',
  components: {
    PageNotFoundMou,
  },
  data() {
    return {
      isLoading: false,
      isLoadingMouLocation: false,
      isLoadingMouTransportTypes: false,
      isErrorMouLocation: false,
      isErrorMouTransportTypes: false,
      headersTableLocation: [
        {
          text: this.$_strings.mou.LOCATION_NAME,
          value: 'locationName',
        },
        {
          text: `${this.$_strings.mou.CITY}/${this.$_strings.mou.DISTRICT}`,
          value: 'locationCityName',
        },
        {
          text: this.$_strings.mou.ADDRESS,
          value: 'address',
        },
        {
          text: this.$_strings.mou.GOOGLE_MAPS,
          value: 'googleMaps',
          sortable: false,
        },
      ],
      headersTableTransporter: [
        {
          text: this.$_strings.mou.TRANSPORTER_NAME,
          value: 'name',
        },
        {
          text: this.$_strings.mou.DESCRIPTION,
          value: 'description',
          sortable: false,
        },
        {
          text: this.$_strings.mou.IMAGE,
          value: 'image',
          sortable: false,
        },
      ],
      mouLocations: [],
      mouTransportTypes: [],
      totalDataMouLocation: 0,
      totalDataMouTransportTypes: 0,
      mouLocationPagination: {
        page: 1,
        size: 10,
      },
      mouTransportTypesPagination: {
        page: 1,
        size: 10,
      },
    };
  },
  computed: {
    mouBy() {
      const [,, path] = this.$route.path.split('/');
      if (path === 'customer') return 'shipper';
      return 'transporter';
    },
    displayedHeaderLocation() {
      return this.headersTableLocation.map((i) => ({
        ...i,
        class: 'white--text primary text-capitalize',
        cellClass: 'clickable',
      }));
    },
    displayedHeaderTransporter() {
      return this.headersTableTransporter.map((i) => ({
        ...i,
        class: 'white--text primary text-capitalize',
        cellClass: 'clickable',
      }));
    },
  },
  watch: {
    mouLocationPagination: {
      handler() {
        this.fetchMouLocation();
      },
      deep: true,
    },
    mouTransportTypesPagination: {
      handler() {
        this.fetchMouTransportType();
      },
      deep: true,
    },
  },
  methods: {
    getSorting(pagination) {
      const { sortBy, sortDesc } = pagination;
      let sort = '';
      if (sortBy.length > 0) {
        const isDesc = sortDesc[0] ? 'DESC' : 'ASC';
        sort = `${sortBy[0]},${isDesc}`;
      }
      return sort;
    },
    async fetchMouLocation() {
      const { mouId } = this.$route.params;
      const { page, itemsPerPage: size } = this.mouLocationPagination;
      const filters = {
        page: page - 1,
        size,
      };
      if (this.getSorting(this.mouLocationPagination)) filters.sort = this.getSorting(this.mouLocationPagination);
      try {
        this.isLoadingMouLocation = true;
        this.isErrorMouLocation = false;
        const result = await this.$_services.mou.getMouLocation(filters, { mouBy: this.mouBy, mouId });
        this.mouLocations = result.data.contents;
        this.totalDataMouLocation = result.data.totalData;
      } catch {
        this.isErrorMouLocation = true;
      } finally {
        this.isLoadingMouLocation = false;
      }
    },

    async fetchMouTransportType() {
      const { mouId } = this.$route.params;
      const { page, itemsPerPage: size } = this.mouTransportTypesPagination;
      const filters = {
        page: page - 1,
        size,
      };
      if (this.getSorting(this.mouTransportTypesPagination)) filters.sort = this.getSorting(this.mouTransportTypesPagination);
      try {
        this.isLoadingMouTransportTypes = true;
        this.isErrorMouTransportTypes = false;
        const result = await this.$_services.mou.getMouTransportType(filters, { mouBy: this.mouBy, mouId });
        this.mouTransportTypes = result.data.contents;
        this.totalDataMouTransportTypes = result.data.totalData;
      } catch {
        this.isErrorMouTransportTypes = true;
      } finally {
        this.isLoadingMouTransportTypes = false;
      }
    },
  },
};

</script>
