<template>
  <v-container fluid>
    <v-tabs
      background-color="transparent"
      active-class="font-weight-bold"
      v-model="tab"
    >
      <v-tab class="text-capitalize body-1">
        Informasi Perusahaan
      </v-tab>
      <v-tab class="text-capitalize body-1">
        Informasi Kerjasama
      </v-tab>
      <v-tab class="text-capitalize body-1">
        Informasi Admin
      </v-tab>
      <v-tab class="text-capitalize body-1">
        Data Lokasi dan Kendaraan
      </v-tab>
      <v-tab class="text-capitalize body-1">
        Pengaturan Harga
      </v-tab>
      <v-tab class="text-capitalize body-1">
        Open API
      </v-tab>
      <v-tab class="text-capitalize body-1">
        Status Perusahaan
      </v-tab>
      <v-tab class="text-capitalize body-1" v-if="path === 'customer'">
        Setting Integrasi Oracle
      </v-tab>
      <v-tab class="text-capitalize body-1" v-if="path === 'customer'">
        Setting Invoice
      </v-tab>
    </v-tabs>
    <v-tabs-items
      v-model="tab"
      class="transparent"
    >
      <v-tab-item>
        <CompanyInformation
          :formCompanyInformation="formCompanyInformation"
          :readOnly="readOnly"
          :fetchCompanyInformation="fetchCompanyInformation"
        />
      </v-tab-item>
      <v-tab-item>
        <CooperationInformation
          :form="formMou"
          :readonly="true"
          :fetchMouDetail="fetchMouDetail"
        />
      </v-tab-item>
      <v-tab-item>
        <AdminInformation />
      </v-tab-item>
      <v-tab-item>
        <LocationAndVehicleData
          :form="formMou"
          :readonly="true"
          :fetchMouDetail="fetchMouDetail"
        />
      </v-tab-item>
      <v-tab-item>
        <PriceSetting
          :form="formMou"
          :readonly="true"
          :checkboxServiceType="checkboxServiceType"
          :fetchMouDetail="fetchMouDetail"
        />
      </v-tab-item>
      <v-tab-item>
        <OpenAPI
          :form="formCompanyInformation"
          :isLoadingCompanyInformation="isLoadingCompanyInformation"
          @fetchCompanyInformation="fetchCompanyInformation"
        />
      </v-tab-item>
      <v-tab-item>
        <CompanyStatus
          @updateFormStatusCompany="updateFormStatusCompany"
          :isLoadingCompanyInformation="isLoadingCompanyInformation"
          :statusCompany="formCompanyInformation.status"
        />
      </v-tab-item>
      <v-tab-item>
        <OracleIntegrationSetting
          :form="formCompanyInformation"
          :isLoadingCompanyInformation="isLoadingCompanyInformation"
          @fetchCompanyInformation="fetchCompanyInformation"
        />
      </v-tab-item>
      <v-tab-item>
        <InvoiceSetting
          :form="formCompanyInformation"
          :isLoadingCompanyInformation="isLoadingCompanyInformation"
          @fetchCompanyInformation="fetchCompanyInformation"
        />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import CompanyInformation from './Detail/CompanyInformation.vue';
import CooperationInformation from './Detail/CooperationInformation.vue';
import AdminInformation from './Detail/AdminInformation.vue';
import LocationAndVehicleData from './Detail/LocationAndVehicleData.vue';
import PriceSetting from './Detail/PriceSetting/PriceListSetting.vue';
import OpenAPI from './Detail/OpenAPI.vue';
import CompanyStatus from './Detail/CompanyStatus.vue';
import OracleIntegrationSetting from './Detail/OracleIntegrationSetting.vue';
import InvoiceSetting from './Detail/InvoiceSetting.vue';

export default {
  name: 'master-detail-partner-shipper',
  components: {
    CompanyInformation,
    CooperationInformation,
    AdminInformation,
    LocationAndVehicleData,
    PriceSetting,
    OpenAPI,
    CompanyStatus,
    OracleIntegrationSetting,
    InvoiceSetting,
  },
  data() {
    return {
      tab: 0,
      isLoadingCompanyInformation: false,
      formCompanyInformation: {
        id: 2,
        name: '',
        abbreviation: '',
        address: {
          address1: '',
          address2: '',
          districtId: '',
          provinceId: '',
          cityId: '',
          subDistrictId: '',
          postalCode: '',
        },
        billingAddress: {
          address1: '',
          address2: '',
          districtId: '',
          provinceId: '',
          cityId: '',
          subDistrictId: '',
          postalCode: '',
        },
        npwpNo: '',
        npwpAddress: '',
        pkpNo: '',
        presidentDirectorSalutation: '',
        presidentDirector: '',
        jobTitle: '',
        siupNo: '',
        status: '',
        tdpNo: '',
        email: '',
        phoneNo: '',
        faxNo: '',
        companyService: '',
        establishDate: '',
        notes: '',
      },
      formMou: {
        id: 0,
        companyId: '',
        serviceTypes: '',
        mouPeriodStart: '',
        mouPeriodEnd: '',
        isAutoExtend: false,
        autoExtendMonth: '',
        termOfPayment: '',
        isP2pLending: false,
        paymentMethod: 'TRANSFER',
        paymentPicSalutation: 'Bpk',
        paymentPic: '',
        paymentPicMsisdn: '',
        operationalPicSalutation: 'Bpk',
        operationalPic: '',
        operationalPicJobTitle: '',
        operationalPicMsisdn: '',
        isInsurance: false,
        isAuditedByKap: false,
        averageShipment: 0,
        totalAsset: 0,
        ventureCapital: 0,
        shareholder: '',
        bankName: '',
        bankBranch: '',
        bankAccountNameSalutation: 'Bpk',
        bankAccountName: '',
        bankAccountNo: '',
        isIntegratedService: false,
        isTransportation: false,
        isMultiPick: false,
        isMultiDrop: false,
        isBackhauling: false,
        isMultiModa: false,
        isMultiShipper: false,
        isWarehousing: false,
        isPriceFixed: false,
        isPriceKilo: false,
        isPriceVolume: false,
        isPriceCarton: false,
        activeStatus: '',
        p2pLending: [],
        mouLocations: [],
        mouTransportTypes: [],
        mouTransportTypeLimit: [],
        mouRoutes: [],
      },
      checkboxServiceType: {
        isFCL: false,
        isBackhauling: false,
        isLCL: false,
      },
      // LOADERS
      isLoadingFclRoute: false,
      isLoadingFclBackhaulingRoute: false,
      isLoadingLclRoute: false,
      // ERRORS
      isErrorFclRoute: false,
      isErrorFclBackhaulingRoute: false,
      isErrorLclRoute: false,
      // FILTERS
      filterFclRoute: {
        page: 1,
        itemsPerPage: 10,
        totalData: 0,
        originId: null,
        destinationId: null,
      },
      filterFclBackhaulingRoute: {
        page: 1,
        itemsPerPage: 10,
        totalData: 0,
        originId: null,
        destinationId: null,
      },
      filterLclRoute: {
        page: 1,
        itemsPerPage: 10,
        totalData: 0,
        originId: null,
        destinationId: null,
      },
    };
  },
  computed: {
    path() {
      const [,, path] = this.$route.path.split('/');
      return path;
    },
    readOnly() {
      const [,,, p] = this.$route.path.split('/');
      return p === 'detail';
    },
  },
  methods: {
    updateFormStatusCompany(newStatus) {
      this.formCompanyInformation.status = newStatus;
    },
    async fetchCompanyInformation() {
      const { companyId } = this.$route.params;
      try {
        let service;
        if (this.path === 'customer') service = this.$_services.masterShipper.fetchDetailCustomer;
        if (this.path === 'rekanan') service = this.$_services.masterPatner.fetchDetailRekanan;
        this.isLoadingCompanyInformation = true;
        const result = await service(companyId);
        this.formCompanyInformation = result.data;
      } finally {
        this.isLoadingCompanyInformation = false;
        this.isLoading = false;
      }
    },
    async fetchMouDetail(mouId) {
      let services;
      if (this.path === 'customer') {
        services = this.$_services.mou.fetchDetailMouShipper;
      } else {
        services = this.$_services.mou.fetchDetailMouTransporter;
      }
      try {
        const result = await services(mouId);
        this.formMou = {
          ...this.formMou,
          ...result.data,
          termOfPayment: +result.data.termOfPayment,
          mouPeriodStart: dayjs(result.data.mouPeriodStart).format(),
          mouPeriodEnd: dayjs(result.data.mouPeriodEnd).format(),
          p2pLending: result.data.p2pLending ? result.data.p2pLending : [], // must array
        };
        // set checkbox
        this.checkboxServiceType.isFCL = this.formMou.serviceTypes.split(',').includes('FCL');
        this.checkboxServiceType.isBackhauling = this.formMou.serviceTypes.split(',').includes('FCL_BACKHAULING');
        this.checkboxServiceType.isLCL = this.formMou.serviceTypes.split(',').includes('LCL');
      } finally {
        this.$root.$loading.hide();
      }
    },
  },
};
</script>
