<template>
  <v-container fluid class="white">
    <section v-if="isLoading">
      <v-row v-for="skeleton in 3" :key="skeleton">
        <v-col cols="12" md="3">
          <v-skeleton-loader
            type="list-item"
          ></v-skeleton-loader>
        </v-col>
        <v-col cols="12" md="9">
          <v-skeleton-loader
            type="image"
            height="35"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row v-for="skeleton in 3" :key="skeleton * 5">
        <v-col class="pa-0" offset-md="3" cols="12" md="8">
          <v-skeleton-loader
            type="list-item"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row v-for="skeleton in 3" :key="skeleton * 4">
        <v-col cols="12" md="3">
          <v-skeleton-loader
            type="list-item"
          ></v-skeleton-loader>
        </v-col>
        <v-col cols="12" md="9">
          <v-skeleton-loader
            type="image"
            height="35"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </section>
    <v-form
      v-else
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <common-text-input
        :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.companyInformation.COMPANY_NAME_INPUT_LABEL)]"
        :inputLabel="$_strings.companyInformation.COMPANY_NAME_INPUT_LABEL"
        v-model="form.name"
        :disabled="true"
      />
      <common-text-input
        :rules="rules.alias"
        :maxLength="5"
        :inputLabel="$_strings.companyInformation.ALIAS_INPUT_LABEL"
        v-model="form.abbreviation"
        :disabled="readOnly"
      />
      <common-text-input
        :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.companyInformation.COMPANY_ADDRESS_INPUT_LABEL)]"
        :inputLabel="$_strings.companyInformation.COMPANY_ADDRESS_INPUT_LABEL"
        v-model="form.address.address1"
        isTextArea
        :disabled="readOnly"
      />
      <v-row no-gutters>
        <v-col offset-md="2">
          <common-autocomplete-input
            :inputLabel="$_strings.locations.PROVINCE_INPUT_LABEL"
            @searchInputChanged="i => provinceSearch = i"
            :loading="isLoadingProvinceList"
            v-model="form.address.provinceId"
            :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.locations.PROVINCE_INPUT_LABEL)]"
            :label="$_strings.locations.PROVINCE_INPUT_LABEL"
            item-text="text"
            item-value="value"
            :items="provinceList"
            :disabled="readOnly"
            @change="
              value => getListItems(
                {
                  value,
                  type: 'city',
                  items: 'cityList',
                  loading: 'isLoadingCityList',
                  service: 'cities'
                }
              )"
          />
        </v-col>
        <v-col>
          <common-autocomplete-input
            :inputLabel="$_strings.locations.CITY_INPUT_LABEL"
            :search-input.sync="citySearch"
            :loading="isLoadingCityList"
            v-model="form.address.cityId"
            :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.locations.CITY_INPUT_LABEL)]"
            :label="$_strings.locations.CITY_INPUT_LABEL"
            item-text="text"
            item-value="value"
            :items="cityList"
            :disabled="readOnly"
            @change="
              value => getListItems(
                {
                  value,
                  type: 'district',
                  items: 'districtList',
                  loading: 'isLoadingDistrictList',
                  service: 'districts'
                }
              )"
          />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col offset-md="2">
          <common-autocomplete-input
            :inputLabel="$_strings.locations.DISTRICT_INPUT_LABEL"
            :search-input.sync="districtSearch"
            :loading="isLoadingDistrictList"
            v-model="form.address.districtId"
            :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.locations.DISTRICT_INPUT_LABEL)]"
            :label="$_strings.locations.DISTRICT_INPUT_LABEL"
            item-text="text"
            item-value="value"
            :items="districtList"
            :disabled="readOnly"
            @change="
              value => getListItems(
                {
                  value,
                  type: 'subDistrict',
                  items: 'subDistrictList',
                  loading: 'isLoadingSubDistrictList',
                  service: 'subDistricts'
                }
              )"
          />
        </v-col>
        <v-col>
          <common-autocomplete-input
            :inputLabel="$_strings.locations.SUBDISTRICT_INPUT_LABEL"
            :search-input.sync="subDistrictSearch"
            :loading="isLoadingSubDistrictList"
            v-model="form.address.subDistrictId"
            :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.locations.SUBDISTRICT_INPUT_LABEL)]"
            :label="$_strings.locations.SUBDISTRICT_INPUT_LABEL"
            item-text="text"
            item-value="value"
            :items="subDistrictList"
            :disabled="readOnly"
            @change="
              value => getListItems(
                {
                  value,
                  type: 'postalCode',
                  items: '',
                  loading: 'isLoadingAddresPostalCode',
                  service: ''
                }
              )"
          />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col
          offset-md="2"
          cols="5"
        >
          <common-text-input
            :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.locations.POSTAL_CODE_INPUT_LABEL)]"
            :inputLabel="$_strings.locations.POSTAL_CODE_INPUT_LABEL"
            v-model="form.address.postalCode"
            :labelWidth="4"
            :disabled="readOnly"
            :loading="isLoadingAddresPostalCode"
          />
        </v-col>
      </v-row>
      <v-row
        no-gutters
        class="ml-3"
      >
        <v-col
          cols="auto"
          :md="2"
          class="mt-1"
        >
          <span class="body-2">
            {{$_strings.companyInformation.BILLING_ADDRESS_INPUT_LABEL}}
          </span>
          <h3 class="error--text d-inline">
            *
          </h3>
        </v-col>
        <v-col class="mt-n3">
          <v-checkbox
            v-model="billingAddressSameAsCompanyAddress"
            :disabled="readOnly"
            :label="$_strings.companyInformation.SAME_AS_COMPANY_ADDRESS_CHECKBOX_LABEL"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col offset-md="2">
          <common-text-input
            :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.companyInformation.BILLING_ADDRESS_INPUT_LABEL)]"
            :inputLabel="$_strings.companyInformation.BILLING_ADDRESS_INPUT_LABEL"
            hideLabel
            v-model="form.billingAddress.address1"
            isTextArea
            :disabled="billingAddressSameAsCompanyAddress || readOnly"
          />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col offset-md="2">
          <common-autocomplete-input
            :inputLabel="$_strings.locations.PROVINCE_INPUT_LABEL"
            :search-input.sync="provinceSearch"
            :loading="isLoadingProvinceList"
            v-model="form.billingAddress.provinceId"
            :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.locations.PROVINCE_INPUT_LABEL)]"
            :label="$_strings.locations.PROVINCE_INPUT_LABEL"
            item-text="text"
            item-value="value"
            :items="provinceList"
            :disabled="billingAddressSameAsCompanyAddress || readOnly"
            @change="
              value => getListItems(
                {
                  value,
                  type: 'billingAddress-city',
                  items: 'cityListBillingAddress',
                  loading: 'isLoadingCityListBillingAddress',
                  service: 'cities'
                }
              )"
          />
        </v-col>
        <v-col>
          <common-autocomplete-input
            :inputLabel="$_strings.locations.CITY_INPUT_LABEL"
            :search-input.sync="citySearch"
            :loading="isLoadingCityListBillingAddress"
            v-model="form.billingAddress.cityId"
            :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.locations.CITY_INPUT_LABEL)]"
            :label="$_strings.locations.CITY_INPUT_LABEL"
            item-text="text"
            item-value="value"
            :items="cityListBillingAddress"
            :disabled="billingAddressSameAsCompanyAddress || readOnly"
            @change="
              value => getListItems(
                {
                  value,
                  type: 'billingAddress-district',
                  items: 'districtListBillingAddress',
                  loading: 'isLoadingDistrictListBillingAddress',
                  service: 'districts'
                }
              )"
          />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col offset-md="2">
          <common-autocomplete-input
            :inputLabel="$_strings.locations.DISTRICT_INPUT_LABEL"
            :search-input.sync="districtSearch"
            :loading="isLoadingDistrictListBillingAddress"
            v-model="form.billingAddress.districtId"
            :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.locations.DISTRICT_INPUT_LABEL)]"
            :label="$_strings.locations.DISTRICT_INPUT_LABEL"
            item-text="text"
            item-value="value"
            :items="districtListBillingAddress"
            :disabled="billingAddressSameAsCompanyAddress || readOnly"
            @change="
              value => getListItems(
                {
                  value,
                  type: 'billingAddress-subDistrict',
                  items: 'subDistrictListBillingAddress',
                  loading: 'isLoadingSubDistrictListBillingAddress',
                  service: 'subDistricts'
                }
              )"
          />
        </v-col>
        <v-col>
          <common-autocomplete-input
            :inputLabel="$_strings.locations.SUBDISTRICT_INPUT_LABEL"
            :search-input.sync="subDistrictSearch"
            :loading="isLoadingSubDistrictListBillingAddress"
            v-model="form.billingAddress.subDistrictId"
            :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.locations.SUBDISTRICT_INPUT_LABEL)]"
            :label="$_strings.locations.SUBDISTRICT_INPUT_LABEL"
            item-text="text"
            item-value="value"
            :items="subDistrictListBillingAddress"
            :disabled="billingAddressSameAsCompanyAddress || readOnly"
            @change="
              value => getListItems(
                {
                  value,
                  type: 'postalCode',
                  items: '',
                  loading: 'isLoadingBillingAddresPostalCode',
                  service: ''
                }
              )"
          />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col
          offset-md="2"
          cols="5"
        >
          <common-text-input
            :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.locations.POSTAL_CODE_INPUT_LABEL)]"
            :inputLabel="$_strings.locations.POSTAL_CODE_INPUT_LABEL"
            v-model="form.billingAddress.postalCode"
            :labelWidth="4"
            :disabled="billingAddressSameAsCompanyAddress || readOnly"
            :loading="isLoadingBillingAddresPostalCode"
          />
        </v-col>
      </v-row>
      <common-text-input
        :inputLabel="$_strings.companyInformation.NPWP_INPUT_LABEL"
        v-model="form.npwpNo"
        v-mask="{'mask': '99.999.999.9-999.999', placeholder: ''}"
        :rules="rules.npwp"
        :disabled="readOnly"
      />
      <common-text-input
        :inputLabel="$_strings.companyInformation.NPWP_ADDRESS_INPUT_LABEL"
        v-model="form.npwpAddress"
        isTextArea
        :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.companyInformation.NPWP_ADDRESS_INPUT_LABEL)]"
        :disabled="readOnly"
      />
      <common-text-input
        :inputLabel="$_strings.companyInformation.PKP_NO_INPUT_LABEL"
        v-model="form.pkpNo"
        isTextArea
        :disabled="readOnly"
      />
      <v-col>
        <v-row>
          <v-col cols="2 pt-0">
            <span class="body-2">
              {{$_strings.companyInformation.COMPANY_PRESIDENT_NAME_INPUT_LABEL}}
            </span>
            <h3 class="error--text d-inline">
              *
            </h3>
          </v-col>
          <v-col cols="2 pt-0">
            <v-select
              outlined
              dense
              :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.companyInformation.COMPANY_PRESIDENT_NAME_INPUT_LABEL)]"
              v-model="form.presidentDirectorSalutation"
              :items="['Bpk', 'Ibu']"
              :disabled="readOnly"
            ></v-select>
          </v-col>
          <v-col cols="ma-0 pa-0">
            <common-text-input
              v-model="form.presidentDirector"
              :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.companyInformation.COMPANY_PRESIDENT_NAME_INPUT_LABEL)]"
              :disabled="readOnly"
            />
          </v-col>
        </v-row>
      </v-col>
      <common-text-input
        :inputLabel="$_strings.companyInformation.POSITION_INPUT_LABEL"
        v-model="form.jobTitle"
        :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.companyInformation.POSITION_INPUT_LABEL)]"
        :disabled="readOnly"
      />
      <common-text-input
        :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.companyInformation.SIUP_NO_INPUT_LABEL)]"
        :inputLabel="$_strings.companyInformation.SIUP_NO_INPUT_LABEL"
        v-model="form.siupNo"
        :disabled="readOnly"
      />
      <common-text-input
        :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.companyInformation.TDP_NO_INPUT_LABEL)]"
        :inputLabel="$_strings.companyInformation.TDP_NO_INPUT_LABEL"
        v-model="form.tdpNo"
        :disabled="readOnly"
      />
      <common-text-input
        :rules="rules.email"
        :inputLabel="$_strings.companyInformation.COMPANY_EMAIL_INPUT_LABEL"
        v-model="form.email"
        :disabled="readOnly"
      />
      <v-row>
        <v-col
          :cols="12"
          :md="2"
          class="pl-3 mt-2 ml-2"
        >
          <span class="body-2">
            {{$_strings.companyInformation.COMPANY_PHONE_NO_INPUT_LABEL}}
          </span>
          <h3 class="error--text d-inline">
            *
          </h3>
        </v-col>
        <v-col cols="4">
          <span class="body-2">
            Kantor
          </span>
          <h3 class="error--text d-inline">
            *
          </h3>
          <phone-number-format
            :placeholder="$_strings.companyInformation.OFFICE_INPUT_LABEL"
            v-model="form.phoneNo"
            outlined
            dense
            :disabled="readOnly"
            :required="readOnly ? false : true"
          ></phone-number-format>
        </v-col>
        <v-col cols="5">
          <span class="body-2">
            Fax
          </span>
          <phone-number-format
            :placeholder="$_strings.companyInformation.FAX_INPUT_LABEL"
            v-model="form.faxNo"
            :disabled="readOnly"
            class="mt-1"
            outlined
            dense
            :fax="true"
            :required="false"
          ></phone-number-format>
        </v-col>
      </v-row>
      <common-text-input
        :inputLabel="$_strings.companyInformation.COMPANY_SERVICE_INPUT_LABEL"
        v-model="form.companyService"
        :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.companyInformation.COMPANY_SERVICE_INPUT_LABEL)]"
        :disabled="readOnly"
      />
      <common-date-input
        :inputLabel="$_strings.companyInformation.COMPANY_ESTABLISHED_YEAR_INPUT_LABEL"
        v-model="form.establishDate"
        :disabled="readOnly"
      />
      <v-container fluid>
        <v-row class="my-n6">
          <v-col
            cols="12"
            md="2"
          >
            <span class="body-2">
              {{$_strings.companyInformation.COMPANY_ADDITIONAL_INFO_INPUT_LABEL}}
            </span>
          </v-col>
          <v-col cols="12" md="10">
            <v-textarea
              no-resize
              outlined
              :placeholder="$_strings.companyInformation.COMPANY_ADDITIONAL_INFO_INPUT_LABEL"
              :disabled="readOnly"
              dense
              v-model="form.notes"
              clearable
              rows="5"
              :counter="255"
              :rules="[(v) => rulesNotes(v, false, 255, $_strings.companyInformation.COMPANY_ADDITIONAL_INFO_INPUT_LABEL)]"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <v-row v-if="!readOnly && !isLoading" align="center" justify-md="end">
      <v-col cols="auto" class="pr-0">
        <v-btn
          @click="resetForm"
          color="primary"
          outlined
        >
          {{$_strings.common.RESET}}
        </v-btn>
      </v-col>
      <v-col cols="auto">
        <v-btn
          @click="onSave"
          color="primary"
        >
          {{$_strings.common.SAVE}}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { rulesNotes } from '@/helper/commonHelpers';

export default {
  name: 'CompanyInformation',
  props: {
    readOnly: {
      type: Boolean,
      default: () => true,
    },
    formCompanyInformation: {
      type: Object,
      default: () => {},
    },
    fetchCompanyInformation: {
      type: Function,
    },
  },
  data() {
    return {
      isLoading: false,
      valid: false,
      rules: {
        alias: [
          (v) => {
            if (!v) {
              return this.$_strings.messages.validation.REQUIRED(this.$_strings.companyInformation.ALIAS_INPUT_LABEL);
            }
            if (v.length < 2) {
              return 'Min 2 karakter Maks 5';
            }
            return true;
          },
        ],
        email: [
          (value) => {
            if (!value) {
              return this.$_strings.messages.validation.REQUIRED(this.$_strings.companyInformation.COMPANY_EMAIL_INPUT_LABEL);
            }
            return this.isEmailValid(value) || this.$_strings.messages.validation.COMPANY_EMAIL_INPUT_LABEL;
          },
        ],
        npwp: [
          (value) => {
            if (!value) return this.$_strings.messages.validation.REQUIRED(this.$_strings.companyInformation.NPWP_INPUT_LABEL);
            const replaceValue = value.replace(/\d/g, '9');
            if (replaceValue !== '99.999.999.9-999.999' && replaceValue.includes('.')) {
              return 'No NPWP Belum Lengkap';
            }
            return true;
          },
        ],
      },
      provinceSearch: null,
      isLoadingProvinceList: false,
      provinceList: [],
      citySearch: null,
      isLoadingCityList: false,
      cityList: [],
      isLoadingCityListBillingAddress: false,
      cityListBillingAddress: [],
      districtSearch: null,
      isLoadingDistrictList: false,
      districtList: [],
      isLoadingDistrictListBillingAddress: false,
      districtListBillingAddress: [],
      subDistrictSearch: null,
      isLoadingSubDistrictList: false,
      subDistrictList: [],
      isLoadingSubDistrictListBillingAddress: false,
      subDistrictListBillingAddress: [],

      // loader postal code
      isLoadingAddresPostalCode: false,
      isLoadingBillingAddresPostalCode: false,
      form: {
        id: 2,
        name: '',
        abbreviation: '',
        address: {
          address1: '',
          address2: '',
          districtId: '',
          provinceId: '',
          cityId: '',
          subDistrictId: '',
          postalCode: '',
        },
        billingAddress: {
          address1: '',
          address2: '',
          districtId: '',
          provinceId: '',
          cityId: '',
          subDistrictId: '',
          postalCode: '',
        },
        npwpNo: '',
        npwpAddress: '',
        pkpNo: '',
        presidentDirectorSalutation: '',
        presidentDirector: '',
        jobTitle: '',
        siupNo: '',
        status: '',
        tdpNo: '',
        email: '',
        phoneNo: '',
        faxNo: '',
        companyService: '',
        establishDate: '',
        notes: '',
      },
    };
  },
  watch: {
    form(newVal) {
      if (newVal.name) this.fetchDataListItems();
    },
  },
  computed: {
    billingAddressSameAsCompanyAddress: {
      get() {
        return (
          (this.form.address.address1 === this.form.billingAddress.address1)
          && (this.form.address.subDistrictId === this.form.billingAddress.subDistrictId)
          && (this.form.address.districtId === this.form.billingAddress.districtId)
          && (this.form.address.cityId === this.form.billingAddress.cityId)
          && (this.form.address.provinceId === this.form.billingAddress.provinceId)
          && (this.form.address.postalCode === this.form.billingAddress.postalCode)
          && ((this.form.address.address1 !== '')
          || (this.form.address.subDistrictId !== '')
          || (this.form.address.districtId !== '')
          || (this.form.address.cityId !== '')
          || (this.form.address.provinceId !== '')
          || (this.form.address.postalCode !== '')
          )
        );
      },
      set(bool) {
        if (bool) {
          this.form.billingAddress.address1 = this.form.address.address1;
          this.form.billingAddress.subDistrictId = this.form.address.subDistrictId;
          this.form.billingAddress.districtId = this.form.address.districtId;
          this.form.billingAddress.cityId = this.form.address.cityId;
          this.form.billingAddress.provinceId = this.form.address.provinceId;
          this.form.billingAddress.postalCode = this.form.address.postalCode;
          // list
          this.cityListBillingAddress = this.cityList;
          this.districtListBillingAddress = this.districtList;
          this.subDistrictListBillingAddress = this.subDistrictList;
        } else {
          this.form.billingAddress.address1 = null;
          this.form.billingAddress.subDistrictId = null;
          this.form.billingAddress.districtId = null;
          this.form.billingAddress.cityId = null;
          this.form.billingAddress.provinceId = null;
          this.form.billingAddress.postalCode = null;
          // list
          this.cityListBillingAddress = [];
          this.districtListBillingAddress = [];
          this.subDistrictListBillingAddress = [];
        }
      },
    },
    path() {
      const [,, path] = this.$route.path.split('/');
      return path;
    },
  },
  methods: {
    rulesNotes,
    resetForm() {
      this.form = JSON.parse(JSON.stringify(this.formCompanyInformation));
      this.fetchDataListItems();
    },
    async fetchData() {
      try {
        this.isLoading = true;
        await this.fetchCompanyInformation();
        this.form = JSON.parse(JSON.stringify(this.formCompanyInformation));
      } finally {
        this.isLoading = false;
      }
    },
    resetListItems(type) {
      switch (type) {
        case 'city':
          this.form.address.cityId = null;
          this.form.address.districtId = null;
          this.form.address.subDistrictId = null;
          this.form.address.postalCode = null;
          this.cityList = [];
          this.districtList = [];
          this.subDistrictList = [];
          break;
        case 'district':
          this.form.address.districtId = null;
          this.form.address.subDistrictId = null;
          this.form.address.postalCode = null;
          this.districtList = [];
          this.subDistrictList = [];
          break;
        case 'subDistrict':
          this.form.address.subDistrictId = null;
          this.form.address.postalCode = null;
          this.subDistrictList = [];
          break;
        case 'billingAddress-city':
          this.form.billingAddress.cityId = null;
          this.form.billingAddress.districtId = null;
          this.form.billingAddress.subDistrictId = null;
          this.form.billingAddress.postalCode = null;
          this.cityListBillingAddress = [];
          this.districtListBillingAddress = [];
          this.subDistrictListBillingAddress = [];
          break;
        case 'billingAddress-district':
          this.form.billingAddress.districtId = null;
          this.form.billingAddress.subDistrictId = null;
          this.form.billingAddress.postalCode = null;
          this.districtListBillingAddress = [];
          this.subDistrictListBillingAddress = [];
          break;
        case 'billingAddress-subDistrict':
          this.form.billingAddress.subDistrictId = null;
          this.form.billingAddress.postalCode = null;
          this.subDistrictListBillingAddress = [];
          break;
        default: break;
      }
    },
    async fetchDataListItems() {
      const {
        provinceId,
        cityId,
        districtId,
      } = this.form.address;
      const {
        provinceId: provinceIdBillingAddress,
        cityId: cityIdBillingAddress,
        districtId: districtIdBillingAddress,
      } = this.form.billingAddress;
      if (this.billingAddressSameAsCompanyAddress) {
        this.fetchListItems(provinceId, ['cityList', 'cityListBillingAddress'], 'isLoadingCityList', 'cities');
        this.fetchListItems(cityId, ['districtList', 'districtListBillingAddress'], 'isLoadingDistrictList', 'districts');
        this.fetchListItems(districtId, ['subDistrictList', 'subDistrictListBillingAddress'], 'isLoadingSubDistrictList', 'subDistricts');
        return;
      }
      // ADDRESS
      this.fetchListItems(provinceId, 'cityList', 'isLoadingCityList', 'cities');
      this.fetchListItems(cityId, 'districtList', 'isLoadingDistrictList', 'districts');
      this.fetchListItems(districtId, 'subDistrictList', 'isLoadingSubDistrictList', 'subDistricts');
      // BILLING ADDRESS
      this.fetchListItems(provinceIdBillingAddress, 'cityListBillingAddress', 'isLoadingCityListBillingAddress', 'cities');
      this.fetchListItems(cityIdBillingAddress, 'districtListBillingAddress', 'isLoadingDistrictListBillingAddress', 'districts');
      this.fetchListItems(districtIdBillingAddress, 'subDistrictListBillingAddress', 'isLoadingSubDistrictListBillingAddress', 'subDistricts');
    },
    async getZipCode(provinceName, cityName, districtName, subDistrictName, isLoading) {
      this[isLoading] = true;
      try {
        const result = await this.$_services.locations.getZipCode(provinceName, cityName, districtName, subDistrictName);
        if (isLoading === 'isLoadingAddresPostalCode') {
          this.form.address.postalCode = result.data;
        } else {
          this.form.billingAddress.postalCode = result.data;
        }
      } finally {
        this[isLoading] = false;
      }
    },
    async fetchProvinceList() {
      this.isLoadingProvinceList = true;
      try {
        const result = await this.$_services.locations.provinces();
        this.provinceList = result.data.contents.map((province) => ({
          text: province.name,
          value: province.id,
        }));
      } finally {
        this.isLoadingProvinceList = false;
      }
    },
    async fetchListItems(value, items, loading, service) {
      this[loading] = true;
      try {
        const result = await this.$_services.locations[service](null, value);
        if (typeof items === 'object') {
          items.forEach((item) => {
            this[item] = result.data.contents.map((data) => ({
              text: data.name,
              value: data.id,
            }));
          });
        } else {
          this[items] = result.data.contents.map((data) => ({
            text: data.name,
            value: data.id,
          }));
        }
        return result;
      } finally {
        this[loading] = false;
      }
    },
    async getListItems({
      value,
      type,
      items,
      loading,
      service,
    }) {
      if (!value) return this.resetListItems(type);
      // GET POSTAL CODE
      if (type === 'postalCode') {
        // ITEMS
        const cityList = loading === 'isLoadingAddresPostalCode' ? this.cityList : this.cityListBillingAddress;
        const districtList = loading === 'isLoadingAddresPostalCode' ? this.districtList : this.districtListBillingAddress;
        const subDistrictList = loading === 'isLoadingAddresPostalCode' ? this.subDistrictList : this.subDistrictListBillingAddress;
        // GET ID
        const {
          provinceId,
          cityId,
          districtId,
          subDistrictId,
        } = loading === 'isLoadingAddresPostalCode' ? this.form.address : this.form.billingAddress;
        // FIND OBJEK
        const provinceName = this.provinceList.find((prov) => prov.value === provinceId).text;
        const cityName = cityList.find((city) => city.value === cityId).text;
        const districtName = districtList.find((dist) => dist.value === districtId).text;
        const subDistrictName = subDistrictList.find((subDist) => subDist.value === subDistrictId).text;
        // GET ZIP CODE
        this.getZipCode(provinceName, cityName, districtName, subDistrictName, loading);
        return;
      }
      await this.fetchListItems(value, items, loading, service);
    },

    async onSave() {
      if (!this.$refs.form.validate()) {
        this.$nextTick(() => {
          const el = this.$el.querySelector('.v-messages.error--text:first-of-type');
          this.$vuetify.goTo(el, { offset: 50 });
        });
        return;
      }
      try {
        this.$root.$loading.show();
        const { companyId } = this.$route.params;
        let service = this.$_services.masterShipper.updateShipperCompanyInformation;
        if (this.path === 'rekanan') service = this.$_services.masterPatner.updateTransporterCompanyInformation;
        await service({ companyId, form: this.form });
        this.$dialog.notify.success('Berhasil memperbarui');
      } finally {
        this.$root.$loading.hide();
      }
    },
  },
  mounted() {
    this.fetchData();
    if (!this.provinceList.length) this.fetchProvinceList();
  },
};
</script>
