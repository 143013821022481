<template>
  <v-container fluid class="white" style="min-height:50vh;">
    <section v-if="isLoadingCompanyInformation" >
      <v-row v-for="skeleton in 1" :key="skeleton">
        <v-col cols="12" md="3">
          <v-skeleton-loader
            type="list-item"
          ></v-skeleton-loader>
        </v-col>
        <v-col cols="12" md="5">
          <v-skeleton-loader
            type="image"
            height="35"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </section>
    <section v-else class="mt-4">
      <v-form ref="defaultSelected" v-model="valid" lazy-validation>
        <v-row>
          <v-col cols="12" sm="3" class="pt-5">
            <h4 class="ml-12">Jadikan Satu Invoice : </h4>
          </v-col>
          <v-col cols="12" sm="6" md="7">
            <v-select
              v-model="form.isInvoiceGrouped"
              dense
              outlined
              hide-details
              :items="statusList"
              item-value="key"
              item-text="value"
              :disabled="edit === 'detail'"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-col v-if="userAccess.canUpdate" cols="1" class="ml-md-auto ma-6">
          <v-btn
            class="mt-1"
            color="primary"
            @click="updateStatus"
            :disabled="edit === 'detail'"
          >
            Update
          </v-btn>
        </v-col>
        <v-row>
        </v-row>
      </v-form>
    </section>
  </v-container>
</template>

<script>
export default {
  name: 'invoice-setting',
  props: {
    isLoadingCompanyInformation: {
      type: Boolean,
      default: () => false,
    },
    readonly: {
      type: Boolean,
      default: () => true,
    },
    form: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      valid: false,
      statusList: [
        { key: true, value: 'Ya' },
        { key: false, value: 'Tidak' },
      ],
      rules: {
      },
    };
  },
  computed: {
    myUserInfo() {
      return this.$store.state.user.myUserInfo;
    },
    path() {
      const [,, path] = this.$route.path.split('/');
      return path;
    },
    edit() {
      const [,,, edit] = this.$route.path.split('/');
      return edit;
    },
  },
  methods: {
    async updateStatus() {
      if (!this.$refs.defaultSelected.validate()) {
        return;
      }
      const companyId = this.form.id;
      this.$root.$loading.show();
      try {
        await this.$_services.masterShipper.updateInvoice(companyId, this.form.isInvoiceGrouped);
        this.$dialog.notify.success('Berhasil');
      } catch {
        this.form.isInvoiceGrouped = !this.form.isInvoiceGrouped;
      } finally {
        this.$root.$loading.hide();
      }
    },
  },
};
</script>
