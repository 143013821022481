<template>
  <v-container class="pa-0" fluid>
    <v-row class="mb-0">
      <v-col cols="12">
        <v-data-table
          :key="reFetchData"
          :loading="isLoading"
          :headers="displayedHeaderLCL"
          :items="mouLclRoute"
          :footer-props="{
            showCurrentPage: true,
            showFirstLastPage: true,
            'items-per-page-options': $_item_per_page,
            disablePagination: isLoading,
            disableItemsPerPage: isLoading
          }"
          :item-key="String(Math.random())"
          :server-items-length="totalLclRouteData"
          :options.sync="pagination"
          class="elevation-1"
        >
          <template v-slot:[`header.priceKilo`]="{ header }">
            <div class="d-flex align-center">
              <span class="text-center">{{header.text}}</span>
              <span>
                <v-checkbox
                  color="white"
                  class="d-inline"
                  v-model="form.isPriceKilo"
                  hide-details
                  disabled
                />
              </span>
            </div>
          </template>
          <template v-slot:[`header.priceVolume`]="{ header }">
            <div class="d-flex align-center">
              <span class="text-center">{{header.text}}</span>
              <span>
                <v-checkbox
                  color="white"
                  class="d-inline"
                  v-model="form.isPriceVolume"
                  hide-details
                  disabled
                />
              </span>
            </div>
          </template>
          <template v-slot:[`header.priceCarton`]="{ header }">
            <div class="d-flex align-center">
              <span class="text-center">{{header.text}}</span>
              <span>
                <v-checkbox
                  color="white"
                  class="d-inline"
                  v-model="form.isPriceCarton"
                  hide-details
                  disabled
                />
              </span>
            </div>
          </template>
          <template v-slot:[`item.sequenceOrderNo`]={item}>
            <v-text-field
              dense
              outlined
              disabled
              class="body-2 pt-2"
              :value="item.sequenceOrderNo ? lclMouRouteSequence.find((seqRoute) => seqRoute[item.sequenceOrderNo])[item.sequenceOrderNo]: null"
            ></v-text-field>
          </template>
          <template v-slot:[`item.origin`]={item}>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                  class="blue--text"
                >
                  {{item.originLocationName}}
                </span>
              </template>
              <span>{{$_strings.order.ORIGIN}}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.destination`]={item}>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                  class="blue--text"
                >
                  {{item.destinationLocationName }}
                </span>
              </template>
              <span>{{$_strings.order.DESTINATION}}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.priceKilo`]={item}>
            <p class="text-center">{{formatAsCurrency(item.priceKilo)}}</p>
          </template>
          <template v-slot:[`item.priceVolume`]={item}>
            <p class="text-center">{{formatAsCurrency(item.priceVolume)}}</p>
          </template>
          <template v-slot:[`item.priceCarton`]={item}>
            <p class="text-center">{{formatAsCurrency(item.priceCarton)}}</p>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'lcl-page',
  props: {
    form: {
      type: Object,
      default: () => [],
    },
    mouLclRoute: {
      type: Array,
      default: () => [],
    },
    lclMouRouteSequence: {
      type: Array,
      default: () => [],
    },
    reFetchData: {
      type: Number,
      default: 0,
    },
    totalLclRouteData: {
      type: Number,
      default: 0,
    },
    isLoading: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      headerTableLCL: [
        // {
        //   text: '',
        //   value: 'sequenceOrderNo',
        //   width: '100px',
        //   sortable: false,
        // },
        {
          text: this.$_strings.order.ORIGIN,
          value: 'origin',
          sortable: false,
        },
        {
          text: this.$_strings.order.DESTINATION,
          value: 'destination',
          sortable: false,
        },
        {
          text: this.$_strings.order.ETA,
          value: 'etaDays',
          sortable: false,
        },
        {
          text: 'Tonase(kg) dalam (Rp)',
          value: 'priceKilo',
          sortable: false,
        },
        {
          text: 'Kubikasi(m3) dalam (Rp)',
          value: 'priceVolume',
          sortable: false,
        },
        {
          text: 'Karton(ktn) dalam (Rp)',
          value: 'priceCarton',
          sortable: false,
        },
      ],
      pagination: {
        page: 1,
        itemsPerPage: 10,
      },
    };
  },
  watch: {
    pagination: {
      handler(newVal) {
        this.$emit('fetchMouLclRoute', newVal);
      },
      deep: true,
    },
  },
  computed: {
    displayedHeaderLCL() {
      return this.headerTableLCL.map((i) => ({
        ...i,
        class: 'white--text primary text-capitalize text-center',
        cellClass: 'clickable',
      }));
    },
  },
  methods: {
    formatAsCurrency(value) {
      const formatNumber = Intl.NumberFormat('id-ID');
      return `Rp. ${formatNumber.format(value) || 0}`;
    },
    getStyle(headerBy) {
      switch (headerBy) {
        case '':
          return 'min-width:80px;';
        default: return 'min-width:120px;';
      }
    },
  },
};

</script>

<style lang="scss" scoped>
  .tooltip-icon {
    height: 10px;
    width: 10px;
  }
</style>
